import React from 'react';

const PcSvg = () => (
  <svg
    fill="hsla(var(--p) / 0.4)"
    className="w-28 h-28 "
    version="1.1"
    id="Capa_1"
    viewBox="0 0 490.605 490.605"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M97.208,372.822c-13.371,0-24.25,10.879-24.25,24.25s10.879,24.25,24.25,24.25s24.25-10.879,24.25-24.25
S110.579,372.822,97.208,372.822z M97.208,412.822c-8.685,0-15.75-7.066-15.75-15.75c0-8.686,7.065-15.75,15.75-15.75
c8.685,0,15.75,7.064,15.75,15.75C112.958,405.756,105.893,412.822,97.208,412.822z"
      />
      <path
        d="M486.355,95.77h-291.51V33.941c0-18.271-14.865-33.137-33.138-33.137H33.138C14.865,0.805,0,15.67,0,33.941v422.723
c0,18.271,14.865,33.137,33.138,33.137h127.859h0.711h227.681c2.348,0,4.25-1.902,4.25-4.25v-28.77c0-2.348-1.902-4.25-4.25-4.25
h-63.684v-69.166h160.65c2.348,0,4.25-1.902,4.25-4.25V100.02C490.605,97.672,488.703,95.77,486.355,95.77z M453.201,172.822
H339.208c-2.348,0-4.25,1.902-4.25,4.25c0,2.348,1.902,4.25,4.25,4.25h113.993v159.27c0,4.063-3.304,7.367-7.365,7.367h-250.99
V131.42h250.99c4.061,0,7.365,3.305,7.365,7.367V172.822z M213.342,452.531h-18.496v-69.166h18.496V452.531z M160.997,481.301
H33.138c-13.585,0-24.638-11.053-24.638-24.637V33.941c0-13.584,11.053-24.637,24.638-24.637h128.57
c13.585,0,24.638,11.051,24.638,24.637v422.723c0,13.584-11.053,24.637-24.638,24.637H160.997z M385.139,481.301H183.841
c5.727-5.15,9.648-12.264,10.708-20.27h190.59V481.301z M317.205,430.188h-49.979c-2.348,0-4.25,1.902-4.25,4.25
c0,2.348,1.902,4.25,4.25,4.25h49.979v13.844h-95.363v-51.283h41.912c2.348,0,4.25-1.902,4.25-4.25c0-2.348-1.902-4.25-4.25-4.25 h-41.912v-9.383h95.363V430.188z M482.105,374.865h-287.26v-18.406h250.99c8.748,0,15.865-7.117,15.865-15.867V138.787 c0-8.75-7.117-15.867-15.865-15.867h-250.99v-18.65h287.26V374.865z"
      />
      <path d="M29.208,101.322h138c2.348,0,4.25-1.902,4.25-4.25v-58c0-2.348-1.902-4.25-4.25-4.25h-138c-2.348,0-4.25,1.902-4.25,4.25v58C24.958,99.42,26.86,101.322,29.208,101.322z M33.458,43.322h129.5v49.5h-129.5V43.322z" />
      <path d="M29.208,163.68h138c2.348,0,4.25-1.902,4.25-4.25c0-2.348-1.902-4.25-4.25-4.25h-138c-2.348,0-4.25,1.902-4.25,4.25 C24.958,161.777,26.86,163.68,29.208,163.68z" />
      <path d="M29.208,203.363h138c2.348,0,4.25-1.902,4.25-4.25c0-2.348-1.902-4.25-4.25-4.25h-138c-2.348,0-4.25,1.902-4.25,4.25 C24.958,201.461,26.86,203.363,29.208,203.363z" />
      <path d="M171.458,238.795c0-2.348-1.902-4.25-4.25-4.25h-138c-2.348,0-4.25,1.902-4.25,4.25c0,2.348,1.902,4.25,4.25,4.25h138 C169.556,243.045,171.458,241.143,171.458,238.795z" />
    </g>
  </svg>
);

export default PcSvg;
